import { transformContent } from '@/utils'

export default function () {
  const get = path => this.$path(path, this.$store.state)

  return [
    {
      component: 'Section',
      props: {
        class: '',
        width: 'small',
        style: 'padding-block: 2rem',
      },
      blocks: {
        default: [
          {
            component: 'Section',
            props: {
              id: 'section-title'
            },
            blocks: {
              default: {
                component: 'Text',
                props: {
                  textAlign: 'center'
                },
                data: {
                  content: `<h1 style="margin: 0; font-weight: 500">Kontakt megler</h1>`
                }
              }
            }
          },
          {
            component: 'Text',
            condition: get('Project.item.customFields.contact-page.content'),
            data: {
              content: transformContent(get('Project.item.customFields.contact-page.content')),
            },
            props: {},
          },
          {
            component: 'Flex',
            props: {
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              class: 'contact-list-lead-layout',
              gap: '0rem',
            },
            blocks: {
              default: [
                {
                  component: 'ContactList',
                  condition: (get('Project.item.roles.salesman') || []).length,

                  data: {
                    contacts: get('Project.item.roles.salesman'),
                  },
                  variant: 'vertical',
                  props: {
                    showLogo: false,
                    showCompanyName: false,
                  },
                },
                {
                  component: 'Lead',
                  condition: get('Project.item.id'),
                  data: {
                    project: get('Project.item.id'),
                    reference: { onModel: 'Project', ref: get('Project.item.id') },
                    fields: ['!contact.upsell', '!comment', '!title'],
                    actionLabel: this.$t('submit', { x: '' }),
                  },

                  blocks: {
                    top: [
                      {
                        component: 'Text',
                        condition: get('Project.item.customFields.contact-page.lead-content'),
                        props: {
                          class: 'lead-content',
                        },
                        data: {
                          content: get('Project.item.customFields.contact-page.lead-content'),
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
